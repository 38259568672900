import React from 'react'
import capitalizeFirstLetters from 'capitalize-first-letters';

class TitleFormatter extends React.Component {

  render() {
    const { name } = this.props
    return (
      <>
        <b>
          {capitalizeFirstLetters(name)}
        </b>
      </>
    )
  }
}

export default TitleFormatter
