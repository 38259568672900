import React from 'react'
import { connect } from 'react-redux'
import { Modal, Row, Col, Button } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { MailOutlined, CloseCircleOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/event/actions'
import LeadsList from './LayoutComponents/LeadsList'

// import './download.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class Leads extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isLeadsOpen',
        value: false,
      },
    })
  }

  downloadCSV = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        briefcaseEmailLoading: true,
      },
    })
    dispatch({
      type: actions.SEND_BRIEFCASE_EMAIL,
      payload: {},
    })
  }

  render() {
    const {
      virtualSettings: { isLeadsOpen },
      virtualEvent: { leadDownloadLoading },
    } = this.props

    return (
      <>
        <Modal
          visible={isLeadsOpen}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal"
          width="575px"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>The Leads</h3>
            </Col>
          </Row>
          <Row gutter={24} id="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 185px)">
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<MailOutlined />}
                  onClick={this.downloadCSV}
                  size="medium"
                  style={{ float: 'right' }}
                  loading={leadDownloadLoading}
                >
                  Download Leads
                </Button>
                <br />
                <br />
                {<LeadsList />}
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Leads
