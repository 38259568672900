import actions from './actions'

const initialState = {
  currentScreen: 'liveChat',
  chat: [],
  qanda: {},
  polling: {},
  unreadChatCount: 0,
  unreadqndaCount: 0,
  currentReplyTo: {},
  assignedSessions: [],
  currentSessionDetails: {},
  isSessionFullScreen: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_MESSAGE:
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].concat(action.payload.toAdd),
        [action.payload.countType]: action.payload.count,
      }
    case actions.SET_QUESTION:
      return {
        ...state,
        qanda: {
          ...state.qanda,
          [action.payload.message_id]: action.payload.toAdd,
        },
        unreadqndaCount: action.payload.count,
      }
    case actions.SET_POLLING:
      return {
        ...state,
        polling: {
          ...state.polling,
          [action.payload.message_id]: action.payload.toAdd,
        },
      }
    default:
      return state
  }
}
