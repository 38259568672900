import React from 'react'
import { Row, Col } from 'antd'
import { MessageList } from 'react-chat-elements'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import './react-chat-elements.modified.css'
import styles from '../../style.module.scss'

/* eslint-disable no-unused-expressions */
const mapStateToProps = ({ Chat }) => ({ Chat })
@connect(mapStateToProps)
class ChatContent extends React.Component {
  scrollbars = React.createRef()

  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    const { mounted } = this.state
    mounted && this.scrollbars.current.scrollToBottom()
  }

  render() {
    const {
      Chat: { channelData, currentChat },
    } = this.props
    return (
      <>
        <Row className={styles.chatContent}>
          <Col span={24}>
            <Scrollbars ref={this.scrollbars}>
              {Object.prototype.hasOwnProperty.call(channelData, currentChat) ? (
                <MessageList
                  className="message-list"
                  lockable
                  style={{ width: '50%', fontSize: '30px' }}
                  toBottomHeight="100%"
                  dataSource={channelData[currentChat].messages}
                />
              ) : (
                ''
              )}
            </Scrollbars>
          </Col>
        </Row>
      </>
    )
  }
}

export default ChatContent
