import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import virtualMenu from './virtual/menu/sagas'
import virtualSettings from './virtual/settings/sagas'
import virtualSetup from './virtual/setup/sagas'
import virtualEvent from './virtual/event/sagas'
import virtualUser from './virtual/user/sagas'
import registerVirtualUser from './virtual/registerUser/sagas'
import Chat from './virtual/chat/sagas'
import agenda from './virtual/agenda/sagas'
import streamFeature from './virtual/streamfeature/sagas'
import meetings from './virtual/meetings/sagas'
import photobooth from './virtual/photobooth/sagas'
import payment from './virtual/payment/sagas'
import analytics from './virtual/analytics/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    virtualMenu(),
    virtualSettings(),
    virtualSetup(),
    virtualEvent(),
    virtualUser(),
    registerVirtualUser(),
    Chat(),
    agenda(),
    streamFeature(),
    meetings(),
    photobooth(),
    payment(),
    analytics(),
  ])
}
