import React from 'react'
import { connect } from 'react-redux'
import Iframe from 'react-iframe'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
// import actions from 'redux/virtual/event/actions'

import './three.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class ThreeDimensionalModel extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isProductDemoVisible',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isProductDemoVisible },
      virtualEvent: { currentProductDemoUrl, currentProductDemoHeight },
    } = this.props
    return (
      <>
        <Modal
          visible={isProductDemoVisible}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal threeModal"
          width="60vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: '#262626', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Iframe
            url={currentProductDemoUrl}
            width="100%"
            height={`${currentProductDemoHeight}px`}
            id="threedmodel"
            className="myClassname"
            display="initial"
            position="relative"
            frameborder="0"
          />
        </Modal>
        {/* <Row>
          <Col span={24}>
            
          </Col>
        </Row> */}
      </>
    )
  }
}

export default ThreeDimensionalModel
