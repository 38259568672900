import React from 'react'
import { Avatar, Row, Col, Space, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

class VisitingCard extends React.Component {
  render() {
    const { userData } = this.props
    console.log(userData)
    console.log(this.props)
    return (
      <Space>
        <Row>
          <Col span={10}>
            <Avatar
              src={userData.avatar}
              shape="square"
              size={80}
              style={{ borderRadius: '8px' }}
            />
          </Col>
          <Col span={12}>
            <Row justify="space-around" align="middle">
              <Col span={24}>
                <h4>
                  <b>{userData.name}</b>
                </h4>
              </Col>
            </Row>
            <Row justify="space-around" align="middle">
              <Col span={24}>
                <h6>{`${userData.designation},`}</h6>
              </Col>
            </Row>
            <Row justify="space-around" align="middle">
              <Col span={24}>
                <h6>{userData.organization}</h6>
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <Button type="primary" ghost shape="circle" icon={<DownloadOutlined />} size="small" />
          </Col>
        </Row>
      </Space>
    )
  }
}

export default VisitingCard
