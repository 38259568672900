export async function getLeftMenuData() {
  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    {
      title: 'Briefcase',
      key: 'briefcase',
      icon: 'icmn icmn-briefcase',
    },
  ]
}
export async function getTopMenuData(navigationData, isExhibitor) {
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }
  console.log(navigationData)
  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Briefcase',
      key: 'briefcase',
      icon: 'icmn icmn-briefcase',
    },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    {
      title: 'Meetings',
      key: 'meeting',
      icon: 'icmn icmn-user-tie',
    },
    thefinalLeg,
  ]
}
