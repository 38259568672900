import API from '../config/api'

export async function getDefaultScene(data) {
  return API.get('getDefaultScene/live', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function getSelectedScene(data) {
  return API.post(
    'getScene',
    { id: data.id },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function getSelectedAgenda(data) {
  return API.get(`getAgenda/${data.id}`)
}

export async function saveBriefcase(data) {
  return API.post(
    `addToBriefcase`,
    { id: data.id },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function getBriefcase(data) {
  return API.get(`getBriefcase`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function removeBriefcase(data) {
  return API.post(
    `deleteFromBriefcase`,
    { id: data.id },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function sendBriefcaseEmail(data) {
  return API.get(`emailBriefcase`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function saveLead(data) {
  return API.post(`saveLead`, data.content, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function assignChair(data) {
  return API.post(`assignSocialTable`, data.content, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function leaveChair(data) {
  return API.get(`leaveSocialTable`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function getTopology(data) {
  return API.get(`getNetworkTopology`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
