import axios from 'axios'

/* eslint-disable no-unused-vars */
const superlocalURL = `https://localhost:443/api/`
const semiProductionURL = `https://52bbed0bd10e.ngrok.io/api/`
const productionURL = `https://virtual.wiz365.io/api/`
const annesprodURL = `https://annesstudios.virtualplatform.in/api/`
export default axios.create({
  baseURL: annesprodURL,
})
