import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, getUser, logout } from 'services/virtual/user'
import capitalizeFirstLetters from 'capitalize-first-letters'
import streamActions from 'redux/virtual/streamfeature/actions'
import actions from './actions'
import eventActions from '../event/actions'
import menuActions from '../menu/actions'

export function* LOGIN(data) {
  try {
    const result = yield call(login, { finalData: data.payload })
    if (result.status === 200) {
      if (result.data.active_status) {
        if (!result.data.kick_out) {
          if (!result.data.login_status) {
            if (result.data.payment_status) {
              localStorage.setItem('acctoken', result.data.accessToken)
              localStorage.setItem('userid', result.data.id)
              localStorage.setItem('tokenType', result.data.tokenType)
              yield put({
                type: actions.LOGIN_HELPER,
                payload: {
                  accessToken: result.data.accessToken,
                  id: result.data.id,
                  tokenType: result.data.tokenType,
                },
              })
            } else {
              notification.error({
                message: 'Trouble with account',
                duration: 12,
                description:
                  'Your account is not yet approved. It could be because of incomplete registration process. Kindly contact the admin right now.',
              })
              localStorage.clear()
              yield put({
                type: actions.SET_STATE,
                payload: {
                  loading: false,
                },
              })
            }
          } else {
            notification.error({
              message: 'Multiple Session Detected',
              duration: 12,
              description:
                'Your account is logged in another device. Kindly logout from the other device to login here. If you think this is an issue, kindly contact the admin.',
            })
            localStorage.clear()
            yield put({
              type: actions.SET_STATE,
              payload: {
                loading: false,
              },
            })
          }
        } else {
          notification.error({
            message: 'You Account has been blocked',
            duration: 12,
            description:
              'The account has been blocked by the admin. It could be due to voilation of our policies. Kindly contact the admin now.',
          })
          localStorage.clear()
          yield put({
            type: actions.SET_STATE,
            payload: {
              loading: false,
            },
          })
        }
      } else {
        notification.error({
          message: "We haven't started yet.",
          duration: 12,
          description:
            "We love your eagerness, but the show hasn't started yet. Log back in at the designated time.",
        })
        localStorage.clear()
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        })
      }
    } else {
      notification.error({
        message: 'Status Invalid',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })
      localStorage.clear()
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* LOGIN_HELPER(data) {
  try {
    const detailResult = yield call(getUser, {
      id: data.payload.id,
      token: data.payload.accessToken,
    })
    if (detailResult.status === 200) {
      notification.success({
        message: 'Access Granted',
        description: 'Welcome to the Virtual Expo 2020.',
      })

      console.log("helping edit profile")
      yield put({
        type: eventActions.SET_STATE,
        payload: {
          isEditProfileOpen: true
        }
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          id: data.payload.id,
          name: capitalizeFirstLetters(detailResult.data.name),
          number: `+${detailResult.data.number}`,
          roles: detailResult.data.roles,
          email: detailResult.data.email,
          organization: capitalizeFirstLetters(detailResult.data.organization),
          designation: capitalizeFirstLetters(detailResult.data.designation),
          avatarType: detailResult.data.avatar_type,
          avatarData: detailResult.data.avatar_data,
          avatarColor: detailResult.data.avatar_color,
          accessToken: data.payload.accessToken,
          tokenType: data.payload.tokenType,
          assignedScene: detailResult.data.assignedScene,
          authorized: true,
          loading: false,
        },
      })

      yield put({
        type: menuActions.GET_DATA,
      })

      yield put({
        type: streamActions.SET_STATE,
        payload: {
          assignedSessions: detailResult.data.assignedSessions,
        },
      })
    } else {
      notification.error({
        message: 'Error Fetching Details',
        description: 'There is an issue with your account, kindly contact the administrator.',
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.clear()
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      number: '',
      roles: '',
      email: '',
      organization: '',
      designation: '',
      avatarType: '',
      avatarData: '',
      avatarColor: '',
      accessToken: '',
      tokenType: '',
      assignedScene: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_HELPER, LOGIN_HELPER),
    takeEvery(actions.LOGOUT, LOGOUT),
  ])
}
