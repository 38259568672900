import React from 'react'
import { connect } from 'react-redux'
import { DatePicker, Row, Col } from 'antd'
import moment from 'moment'
import actions from 'redux/virtual/meetings/actions'

@connect(({ meetings }) => ({ meetings }))
class DateForm extends React.Component {
  onChange = (date, dateString) => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        selectedTimeSlotString: dateString,
        selectedTimeSlot: date,
      },
    })
  }

  disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().startOf('day')
  }

  render() {
    const {
      meetings: { selectedTimeSlot },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <h5>Select slot for meeting</h5>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              disabledDate={this.disabledDate}
              onChange={this.onChange}
              // disabledTime={this.disabledDateTime}
              use12Hours
              minuteStep={15}
              showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
              defaultValue={selectedTimeSlot}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default DateForm
