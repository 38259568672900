import React from 'react'
import { Popover, Row, Col, Divider, Button, Avatar, Space } from 'antd'
import { connect } from 'react-redux'
// import { push } from 'react-router-redux'
import actions from 'redux/virtual/event/actions'
import { BellFilled, PlayCircleFilled } from '@ant-design/icons'
import SpeakerMiniProfile from '../SpeakerMiniProfile'

import './session.less'

const mapStateToProps = ({ router }) => ({ router })
@connect(mapStateToProps)
class SessionList extends React.Component {
  initiateSession = event => {
    const { dispatch } = this.props
    const id = event.target.getAttribute('data-id')
    const scene = event.target.getAttribute('data-room')
    const url = event.target.getAttribute('data-url')
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
    dispatch({
      type: actions.FILTER_SCENE,
      payload: {
        id: scene,
        navigated: true,
        componentRendered: false,
        sessionID: id,
        currentSessionURL: url,
      },
    })
    // dispatch(push(`${pathname}?scene=${scene}&session=${id}`))
  }

  getAgendaItem = item => {
    const startTime = item[1].startTime.split(' ')[1]
    const endTime = item[1].endTime.split(' ')[1]
    const { isVirtualMobileView } = this.props
    const theSpeakers = Object.entries(item[1].speakers).map(key => {
      console.log('inside speakers')
      console.log(key[1])
      return (
        <Popover
          overlayClassName="speakerpopover"
          content={<SpeakerMiniProfile speakerData={key[1]} />}
        >
          <Avatar
            src={key[1].imageUrl}
            border
            borderColor="white"
            shape="square"
            size={75}
            style={{ borderRadius: '10px' }}
          />
        </Popover>
      )
    })
    return (
      <div className="sessionItem">
        <Row gutter={0} className="sessionHeader" justify="space-around" align="middle">
          <Col xs={13} md={10} lg={8} xl={6} className="sessionTime text-center">
            <h3>
              {startTime} - {endTime}
              <Divider type="vertical" />
              <BellFilled />
            </h3>
          </Col>
          {isVirtualMobileView ? (
            <>
              <Col xs={1} />
              <Col xs={10}>
                <Button
                  type="primary"
                  shape="round"
                  data-url={item[1].url}
                  data-id={item[0]}
                  data-room={item[1].assignedRoom}
                  icon={<PlayCircleFilled />}
                  onClick={this.initiateSession}
                  size="small"
                >
                  Join Session
                </Button>
                {/* <a href={item[1].url} target="_blank" rel="noopener noreferrer">
                  <Button
                    type="primary"
                    shape="round"
                    data-url={item[1].url}
                    data-id={item[0]}
                    data-room={item[1].assignedRoom}
                    icon={<PlayCircleFilled />}
                    // onClick={this.initiateSession}
                    size="small"
                  >
                    Join Session
                  </Button>
                </a> */}
              </Col>
            </>
          ) : (
            ''
          )}
          <Col xs={0} md={1} lg={1} xl={1} />
          <Col xs={24} md={13} lg={15} xl={17} className="sessionTitle">
            <h3>{item[1].title}</h3>
          </Col>
        </Row>
        {isVirtualMobileView ? (
          ''
        ) : (
          <Row gutter={0} className="sessionActions" justify="space-around" align="middle">
            <Col xs={24} md={10} lg={8} xl={6}>
              <Button
                type="primary"
                shape="round"
                data-url={item[1].url}
                data-id={item[0]}
                data-room={item[1].assignedRoom}
                icon={<PlayCircleFilled />}
                onClick={this.initiateSession}
                size="medium"
              >
                Join Session
              </Button>
              {/* <a href={item[1].url} target="_blank" rel="noopener noreferrer">
                <Button
                  type="primary"
                  shape="round"
                  data-url={item[1].url}
                  data-id={item[0]}
                  data-room={item[1].assignedRoom}
                  icon={<PlayCircleFilled />}
                  // onClick={this.initiateSession}
                  size="small"
                >
                  Join Session
                </Button>
              </a> */}
            </Col>
            <Col xs={0} md={14} lg={16} xl={18} />
          </Row>
        )}
        <Row className="sessionContent">
          <Col span={24}>
            <p>{item[1].description}</p>
          </Col>
        </Row>
        <Divider />
        <Row className="speakerProfile">
          <Col span={24}>
            <Space>{theSpeakers}</Space>
          </Col>
        </Row>
        {/* // <Row gutter={24} id="agendaFooter" className="agendaFooter">
        //   <Col span={24}>
        //     <img src="./resources/images/agendaFooter.jpg" style={{width:"100%"}} alt="modalFooter" />
        //   </Col>
        // </Row> */}
      </div>
    )
  }

  render() {
    const { sessions } = this.props
    const showcase = Object.entries(sessions).map(key => {
      return this.getAgendaItem(key)
    })
    return <div>{showcase}</div>
  }
}

export default SessionList
