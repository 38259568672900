import actions from './actions'

const initialState = {
  scene: {},
  dimensions: {},
  currentMeeting: '',
  meetingVisible: false,
  componentRendered: false,
  isSceneAgendaOpen: false,
  currenSceneAgenda: {},
  agendaLoading: true,
  sessionRender: false,
  currentSession: '',
  currentSessionURL: '',
  isDocumentListVisible: false,
  currentClickSection: '',
  isQuickViewOpen: false,
  currentFile: {},
  isBriefCaseCalled: false,
  briefcase: {},
  briefcaseLoading: true,
  briefcaseEmailLoading: false,
  navigation: [],
  isDropCardVisible: false,
  hideNumber: false,
  leads: {},
  leadDownloadLoading: false,
  customMessageCard: "Hey! Let's connect",
  currentProductDemoUrl: '',
  currentProductDemoHeight: '',
  theHistory: [],
  networkingZoneTopology: {},
  assignedTable: '',
  assignedChair: '',
  isSocialWallOpen: '',
  socialWallURL: '',
  isEditProfileOpen: ''
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_LEAD:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.payload.actualload.id]: action.payload.actualload,
        },
      }
    default:
      return state
  }
}
