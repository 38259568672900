import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import actions from 'redux/virtual/photobooth/actions'

import './overlay.less'

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

const overlays = {
  1: {
    imagePath: 'resources/images/overlay/overlay01.png',
  },
  2: {
    imagePath: 'resources/images/overlay/overlay02.png',
  },
  3: {
    imagePath: 'resources/images/overlay/overlay03.png',
  },
  4: {
    imagePath: 'resources/images/overlay/overlay04.png',
  },
}

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Overlay extends React.Component {
  state = {
    overlayApplied: false,
    currentoverlay: 1,
  }

  retake = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  applyOverlay = event => {
    const index = event.target.getAttribute('data-index')
    this.setState({ overlayApplied: true, currentoverlay: index })
  }

  goNext = () => {
    const {
      dispatch,
      photobooth: { currentStep, imageSrc },
    } = this.props
    const { currentoverlay, overlayApplied } = this.state

    if (overlayApplied) {
      const canvas = document.createElement('canvas')
      canvas.id = 'overlayImage'
      canvas.width = 600
      canvas.height = 600
      const ctx = canvas.getContext('2d')

      const image = new Image()
      image.onload = function() {
        ctx.drawImage(image, 0, 0)
      }
      image.src = imageSrc

      const overlayImage = new Image()
      overlayImage.src = overlays[currentoverlay].imagePath
      overlayImage.onload = () => {
        ctx.drawImage(overlayImage, 0, 0)
        const finalImage = canvas.toDataURL()
        dispatch({
          type: actions.SET_STATE,
          payload: {
            imageSrc: finalImage,
          },
        })
      }
    }
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
  }

  render() {
    const {
      photobooth: { imageSrc },
    } = this.props
    const { currentoverlay, overlayApplied } = this.state
    const slides = Object.entries(overlays).map(key => {
      return (
        <div>
          <img
            src={key[1].imagePath}
            alt={`overlay${key[0]}`}
            data-index={key[0]}
            onClick={this.applyOverlay}
            style={{ width: '100%' }}
          />
        </div>
      )
    })
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            {overlayApplied ? (
              <img
                src={overlays[currentoverlay].imagePath}
                alt="overlayImage"
                className="overlayimage"
              />
            ) : (
              ''
            )}
            <img src={imageSrc} alt="newImage" style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="containerSlider">
            <Slider {...settings}>{slides}</Slider>
          </Col>
        </Row>

        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="primary" onClick={this.goNext} size="medium">
              Next &nbsp; <RightOutlined />
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default Overlay
