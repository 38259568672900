import React from 'react'
import { Modal, Row, Col, Tabs, Skeleton } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
// import actions from 'redux/virtual/agenda/actions'
// import Scrollbars from 'react-custom-scrollbars'
import SessionList from './SessionList'

import './agenda.less'

const { TabPane } = Tabs
const mapStateToProps = ({ virtualSettings, agenda }) => ({ virtualSettings, agenda })

@connect(mapStateToProps)
class Agenda extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isAgendaOpen',
        value: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isAgendaOpen, isVirtualMobileView },
      agenda: { agendas, loading },
    } = this.props

    const showcase = Object.entries(agendas)
      .map(key => {
        return (
          <TabPane
            tab={
              <span>
                {key[1].name}
                <br />
                {key[1].date}
              </span>
            }
            key={key[0]}
          >
            <SessionList
              sessions={key[1].agendaItems}
              isVirtualMobileView={isVirtualMobileView}
              loading={loading}
            />
          </TabPane>
        )
      })
      .reverse()
    return (
      <div>
        <Modal
          visible={isAgendaOpen}
          style={{
            top: 0,
            display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="agendaModal"
          maskClosable={false}
          mask
          width="60vmax"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          // destroyOnClose
        >
          <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row>
          <Row gutter={24} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 250px)">
              <Col span={24}>
                <Skeleton loading={loading} active>
                  <Tabs tabPosition={isVirtualMobileView ? 'top' : 'left'}>{showcase}</Tabs>
                </Skeleton>
              </Col>
            </Scrollbars>
          </Row>
          <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default Agenda
