import React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import store from 'store'
import _ from 'lodash'
import actions from 'redux/virtual/event/actions'
import agendaActions from 'redux/virtual/agenda/actions'
import styles from './style.module.scss'
import ProfileMenu from './ProfileMenu'

const { SubMenu, Divider } = Menu

const mapStateToProps = ({ virtualMenu, virtualSettings, virtualUser, virtualEvent, Chat }) => ({
  menuData: virtualMenu.menuTopData,
  isVirtualLightTheme: virtualSettings.isVirtualLightTheme,
  isSidebarOpen: virtualSettings.isSidebarOpen,
  avatarType: virtualUser.avatarType,
  avatarData: virtualUser.avatarData,
  avatarColor: virtualUser.avatarColor,
  name: virtualUser.name,
  designation: virtualUser.designation,
  organization: virtualUser.organization,
  isAgendaOpen: virtualSettings.isAgendaOpen,
  isSpeakersOpen: virtualSettings.isSpeakersOpen,
  isBriefcaseOpen: virtualSettings.isBriefcaseOpen,
  isLeadsOpen: virtualSettings.isLeadsOpen,
  isMeetingsOpen: virtualSettings.isMeetingsOpen,
  isBriefCaseCalled: virtualEvent.isBriefCaseCalled,
  theHistory: virtualEvent.theHistory,
  chatvisitedonce: Chat.chatvisitedonce,
  currentTab: Chat.currentTab,
})

@withRouter
@connect(mapStateToProps)
class MenuTop extends React.Component {
  state = {
    selectedKeys: store.get('app.virtualMenu.selectedKeys') || [],
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  componentWillReceiveProps(newProps) {
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  handleClick = e => {
    const {
      dispatch,
      isSidebarOpen,
      isAgendaOpen,
      isBriefcaseOpen,
      isLeadsOpen,
      isMeetingsOpen,
      isBriefCaseCalled,
      theHistory,
      chatvisitedonce,
      currentTab,
      isSpeakersOpen,
    } = this.props
    store.set('app.virtualMenu.selectedKeys', [e.key])
    if (e.key === 'sidebar') {
      if (!chatvisitedonce && currentTab === 'attendees') {
        dispatch({
          type: 'chat/GET_ALL_USERS',
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }
    if (e.key === 'back') {
      const tempHistory = theHistory
      tempHistory.pop()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          theHistory: tempHistory,
        },
      })
      dispatch({
        type: actions.FILTER_SCENE,
        payload: {
          id: tempHistory[tempHistory.length - 1],
        },
      })

      return
    }
    if (e.key === 'sidebar') {
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }
    if (e.key === 'agenda') {
      dispatch({
        type: agendaActions.GET_AGENDA,
        payload: {},
      })
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isAgendaOpen',
          value: !isAgendaOpen,
        },
      })
      return
    }
    if (e.key === 'speakers') {
      console.log('clicked on speakers')
      console.log(isSpeakersOpen)
      dispatch({
        type: agendaActions.GET_SPEAKERS,
        payload: {},
      })
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isSpeakersOpen',
          value: !isSpeakersOpen,
        },
      })
      return
    }
    if (e.key === 'briefcase') {
      if (!isBriefCaseCalled) {
        dispatch({
          type: actions.GET_BRIEFCASE,
          payload: {},
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isBriefcaseOpen',
          value: !isBriefcaseOpen,
        },
      })
      return
    }
    if (e.key === 'leads') {
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isLeadsOpen',
          value: !isLeadsOpen,
        },
      })
      return
    }
    if (e.key === 'meeting') {
      if (!isMeetingsOpen) {
        dispatch({
          type: 'meetings/GET_MEETING',
        })
      }
      dispatch({
        type: 'virtualSettings/CHANGE_SETTING',
        payload: {
          setting: 'isMeetingsOpen',
          value: !isMeetingsOpen,
        },
      })
      return
    }
    if (e.key === 'home') {
      dispatch({
        type: actions.GET_DEFAULT_SCENE,
      })
      return
    }
    // if (e.key === 'navigation') {
    //   dispatch({
    //     type: actions.FILTER_NAVIGATION_SCENE,
    //   })
    //   return
    // }
    // if (e.key === 'back') {
    //   if(lastPath != "")
    //   {
    //     dispatch({
    //       type: actions.GET_DEFAULT_SCENE
    //     })
    //   }

    //   return
    // }
    this.setState({
      selectedKeys: [e.key],
    })
  }

  generateMenuItems = () => {
    const { menuData = [] } = this.props
    const generateItem = item => {
      const { key, title, url, icon, pro, disabled } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
                {pro && <span className="badge badge-primary ml-2">PRO</span>}
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
                {pro && <span className="badge badge-primary ml-2">PRO</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${styles.icon}`} />}
          <span className={styles.title}>{title}</span>
          {pro && <span className="badge badge-primary ml-2">PRO</span>}
        </Menu.Item>
      )
    }
    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span className={styles.menu} key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })
    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span className={styles.menu} key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys } = this.state
    const { isVirtualLightTheme } = this.props
    return (
      <div>
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <img src="resources/images/logo-inverse.png" alt="logo" />
          </div>
        </div>
        <Menu
          theme={isVirtualLightTheme ? 'light' : 'dark'}
          onClick={this.handleClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
        >
          {this.generateMenuItems()}
        </Menu>
        <div className={styles.avatar}>
          <div className={styles.avatarContainer}>
            <ProfileMenu style={{ lineHeight: 0 }} />
          </div>
        </div>
      </div>
    )
  }
}

export default MenuTop
