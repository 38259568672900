import React from 'react'
import { Row, Col, Button } from 'antd'
import { CameraOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import actions from 'redux/virtual/photobooth/actions'
import Webcam from 'react-webcam'

const mapStateToProps = ({ photobooth }) => ({ photobooth })
/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate */
@connect(mapStateToProps)
class Capture extends React.Component {
  state = {
    countdown: 3,
    startCountdown: false,
  }

  setRef = webcam => {
    this.webcam = webcam
  }

  capture = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    const { countdown } = this.state
    this.setState({ startCountdown: true })
    setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 })
    }, 1000)
    setTimeout(() => {
      const imageSrc = this.webcam.getScreenshot()
      dispatch({
        type: actions.SET_STATE,
        payload: {
          currentStep: currentStep + 1,
          imageSrc,
          webcamLoading: true,
        },
      })
    }, countdown * 1000 + 100)
  }

  disableLoader = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        webcamLoading: false,
      },
    })
  }

  render() {
    const {
      photobooth: { webcamLoading },
    } = this.props
    const { countdown, startCountdown } = this.state
    const videoConstraints = {
      width: 600,
      height: 600,
      facingMode: 'user',
    }
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center" style={{ width: '100%', minHeight: '400px' }}>
            {webcamLoading ? <Loader /> : ''}
            {startCountdown ? <h2 className="countdown">{countdown}</h2> : ''}
            <Webcam
              audio={false}
              imageSmoothing
              mirrored
              style={{ width: '100%', borderRadius: '7px 7px 7px 7px' }}
              ref={this.setRef}
              minScreenshotHeight={600}
              minScreenshotWidth={600}
              screenshotQuality={1}
              screenshotFormat="image/jpg"
              onUserMedia={this.disableLoader}
              videoConstraints={videoConstraints}
            />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Take a selfie</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button
              type="primary"
              shape="circle"
              onClick={this.capture}
              icon={<CameraOutlined />}
              size="large"
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Capture
