import React from 'react'
import { connect } from 'react-redux'
import { PubNubProvider, PubNubConsumer } from 'pubnub-react'
import actions from 'redux/virtual/chat/actions'
import { notification, Button } from 'antd'
import streamActions from 'redux/virtual/streamfeature/actions'
import eventActions from 'redux/virtual/event/actions'
import { SystemMessage } from 'react-chat-elements'
import styles from './style.module.scss'
import HomeScreen from './MainComponents/HomeScreen'
import ChatWindow from './MainComponents/ChatWindow'
import MeetingInvite from './LayoutComponent/MeetingInvite'
import VisitingCard from './LayoutComponent/VisitingCard'
import QuestionFormat from '../StreamFeatures/LayoutComponent/QuestionFormat'

/* eslint-disable no-lone-blocks, no-unused-expressions, prefer-destructuring, no-case-declarations */
const mapStateToProps = ({
  virtualSettings,
  Chat,
  virtualUser,
  streamFeature,
  agenda,
  virtualEvent,
}) => ({
  isSidebarOpen: virtualSettings.isSidebarOpen,
  currentScreen: Chat.currentScreen,
  pubnub: Chat.pubnub,
  id: virtualUser.id,
  listnerInitialized: Chat.listnerInitialized,
  currentChat: Chat.currentChat,
  channelData: Chat.channelData,
  currentStreamScreen: streamFeature.currentScreen,
  unreadChatCount: streamFeature.unreadChatCount,
  unreadqndaCount: streamFeature.unreadqndaCount,
  roles: virtualUser.roles,
  requestData: Chat.requestData,
  currentSessionDetails: streamFeature.currentSessionDetails,
  qanda: streamFeature.qanda,
  polling: streamFeature.polling,
  agenda: agenda.agendas,
  networkingZoneTopology: virtualEvent.networkingZoneTopology,
})
// const control = 0
@connect(mapStateToProps)
class Chat extends React.Component {
  constructor(props) {
    super(props)

    if (props.roles.includes('ROLE_EXHIBITOR')) {
      props.dispatch({
        type: actions.SET_STATE,
        payload: {
          currentTab: 'requests',
        },
      })
    }

    props.dispatch({
      type: actions.INIT_CHAT,
    })
    // props.dispatch({
    //   type: actions.GET_CHANNELS
    // })
  }

  notificationActions = (key, type, calltoaction) => {
    const { dispatch, agenda } = this.props
    notification.close(key)
    if (type === 'session') {
      let roomID = ''
      let currentURL = ''
      Object.entries(agenda).map(item => {
        if (Object.prototype.hasOwnProperty.call(item[1].agendaItems, calltoaction)) {
          roomID = item[1].agendaItems[calltoaction].assignedRoom
          currentURL = item[1].agendaItems[calltoaction].url
        }
        return ''
      })
      dispatch({
        type: eventActions.FILTER_SCENE,
        payload: {
          id: roomID,
          navigated: true,
          componentRendered: false,
          sessionID: calltoaction,
          currentSessionURL: currentURL,
        },
      })
    } else if (type === 'scene') {
      dispatch({
        type: eventActions.FILTER_SCENE,
        payload: {
          id: calltoaction,
          currentPath: '',
        },
      })
    }
    // else if (type === 'newlead') {
    // }
    // else if (type === 'external') {
    // }
  }

  handleMessages = messageEvent => {
    const {
      dispatch,
      pubnub,
      id,
      currentChat,
      channelData,
      unreadChatCount,
      unreadqndaCount,
      currentStreamScreen,
      requestData,
      roles,
      currentSessionDetails,
      qanda,
      polling,
      networkingZoneTopology,
    } = this.props
    console.log('new message')
    console.log(messageEvent.message)
    switch (messageEvent.message.type) {
      case 'subscribe':
        if (messageEvent.publisher !== id) {
          if (messageEvent.message.ChannelType === 'business' && roles.includes('ROLE_EXHIBITOR')) {
            dispatch({
              type: actions.ADD_REQUESTS,
              payload: {
                actualload: {
                  channelID: messageEvent.message.subscribeTo,
                  channelType: messageEvent.message.ChannelType,
                  chatWith: messageEvent.publisher,
                  requestFor: messageEvent.message.requestFor,
                  userMeta: messageEvent.message.userMeta,
                },
              },
            })
          } else {
            dispatch({
              type: actions.SUBSCRIBE_CHANNELS,
              payload: {
                channels: [messageEvent.message.subscribeTo],
                pubnub,
              },
            })
            dispatch({
              type: actions.ADD_CHANNEL,
              payload: {
                actualload: {
                  channelID: messageEvent.message.subscribeTo,
                  channelType: 'private',
                  chatWith: messageEvent.publisher,
                  messages: [],
                  readStatus: 'unread',
                  unreadCount: 0,
                  userMeta: messageEvent.message.userMeta,
                },
              },
            })
          }
        }
        break
      case 'videomeet':
      case 'visitingcard':
      case 'message':
        if (messageEvent.message.publisher !== id) {
          const utcSeconds = Math.round(messageEvent.timetoken / 10000000)
          const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds)
          let readStatus = 'read'
          if (Object.prototype.hasOwnProperty.call(channelData, messageEvent.message.publisher)) {
            let unreadCount = channelData[messageEvent.message.publisher].unreadCount
            if (currentChat !== messageEvent.message.publisher) {
              readStatus = 'unread'
              unreadCount += 1
            }
            let actualText = messageEvent.message.content
            if (messageEvent.message.type === 'videomeet') {
              actualText = <MeetingInvite id={messageEvent.message.content.props.id} />
            }
            if (messageEvent.message.type === 'visitingcard') {
              actualText = <VisitingCard userData={messageEvent.message.content.props.userData} />
            }

            dispatch({
              type: actions.SET_MESSAGE,
              payload: {
                chatWith: messageEvent.message.publisher,
                lastTime: Math.round(messageEvent.timetoken / 10000000),
                readStatus,
                unreadCount,
                toAdd: {
                  position: 'left',
                  type: 'text',
                  category: messageEvent.message.type,
                  text: actualText,
                  date: d,
                },
              },
            })
          }
        }
        break
      case 'qanda':
      case 'liveChat':
        if (messageEvent.publisher !== id || currentSessionDetails.moderators.length > 0) {
          const utcSeconds = Math.round(messageEvent.timetoken / 10000000)
          const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds)

          let unreadCount = 0
          if (messageEvent.message.type === 'liveChat') {
            unreadCount = unreadChatCount
            if (currentStreamScreen !== 'liveChat') {
              unreadCount += 1
            }
          } else if (messageEvent.message.type === 'qanda') {
            unreadCount = unreadqndaCount
            if (currentStreamScreen !== 'qanda') {
              unreadCount += 1
            }
          }

          const totalModerators = currentSessionDetails.moderators.length
          let actualText = messageEvent.message.content
          let showTitleAvatar = true
          if (messageEvent.message.type === 'qanda') {
            if (totalModerators > 0) {
              if (messageEvent.message.moderationStatus === 'sentforapproval') {
                showTitleAvatar = false
                actualText = (
                  <QuestionFormat
                    messageID={messageEvent.message.message_id}
                    avatarURL={messageEvent.message.avatar}
                    title={messageEvent.message.title}
                    question={messageEvent.message.content}
                    quesStatus={messageEvent.message.moderationStatus}
                  />
                )
              }
            }
          }
          const toAdd = {
            message_id: messageEvent.message.message_id,
            position: messageEvent.message.publisher === id ? 'right' : 'left',
            type: 'text',
            category: messageEvent.message.type,
            originalPublisher: messageEvent.message.publisher,
            text: actualText,
            date: d,
            title: showTitleAvatar ? messageEvent.message.title : '',
            notch: false,
            avatar: showTitleAvatar ? messageEvent.message.avatar : '',
            replyButton: true,
          }
          if (Object.prototype.hasOwnProperty.call(messageEvent.message, 'reply')) {
            if (Object.keys(messageEvent.message.reply).length > 0) {
              toAdd.reply = {
                photoURL: messageEvent.message.reply.avatar,
                title: messageEvent.message.reply.title,
                titleColor: '#FF0000',
                message: messageEvent.message.reply.message,
              }
            }
          }

          if (messageEvent.message.type === 'liveChat') {
            if (messageEvent.publisher !== id) {
              dispatch({
                type: streamActions.SET_MESSAGE,
                payload: {
                  key: 'chat',
                  countType: 'unreadChatCount',
                  count: unreadCount,
                  toAdd,
                },
              })
            }
          } else {
            dispatch({
              type: streamActions.SET_QUESTION,
              payload: {
                key: 'qanda',
                countType: 'unreadqndaCount',
                count: 0,
                toAdd,
                message_id: messageEvent.message.message_id,
              },
            })
          }
        }
        break
      case 'unassignExhibitor':
      case 'assignExhibitor':
        const utcSeconds = Math.round(messageEvent.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        let readStatus = 'read'
        if (
          Object.prototype.hasOwnProperty.call(channelData, messageEvent.message.parentPublisher)
        ) {
          let unreadCount = channelData[messageEvent.message.parentPublisher].unreadCount
          if (currentChat !== messageEvent.message.parentPublisher) {
            readStatus = 'unread'
            unreadCount += 1
          }

          dispatch({
            type: actions.SET_MESSAGE,
            payload: {
              chatWith: messageEvent.message.parentPublisher,
              lastTime: Math.round(messageEvent.timetoken / 10000000),
              readStatus,
              unreadCount,
              toAdd: {
                // position: 'left',
                type: 'text',
                category: messageEvent.message.type,
                text:
                  messageEvent.message.type === 'assignExhibitor' ? (
                    <SystemMessage
                      text={`${messageEvent.message.assignedTo.name} has been assigned to you.`}
                    />
                  ) : (
                    <SystemMessage
                      text={`${messageEvent.message.assignedTo.name} has been unassigned to you.`}
                    />
                  ),
                date: d,
              },
            },
          })

          dispatch({
            type: actions.SET_INSIDE_CHANNEL,
            payload: {
              chatWith: messageEvent.message.parentPublisher,
              key: 'assignedTo',
              value: messageEvent.message.assignedTo,
            },
          })
        }

        break
      case 'polling':
        if (messageEvent.publisher !== id || currentSessionDetails.moderators.length > 0) {
          const utcSecond = Math.round(messageEvent.timetoken / 10000000)
          const dx = new Date(0) // The 0 there is the key, which sets the date to the epoch
          dx.setUTCSeconds(utcSecond)

          const toAdd = {}
          toAdd.message = messageEvent.message
          toAdd.date = dx

          dispatch({
            type: streamActions.SET_POLLING,
            payload: {
              key: 'polling',
              toAdd,
              message_id: messageEvent.message.message_id,
            },
          })
        }
        break

      case 'updatePoll':
        if (messageEvent.publisher !== id) {
          if (Object.prototype.hasOwnProperty.call(polling, messageEvent.message.messageID)) {
            const thePoll = polling[messageEvent.message.messageID]
            const tempVotes = thePoll.message.options[messageEvent.message.optionID].votes
            thePoll.message.options[messageEvent.message.optionID].votes = tempVotes + 1
            dispatch({
              type: streamActions.SET_POLLING,
              payload: {
                key: 'polling',
                toAdd: thePoll,
                message_id: messageEvent.message.messageID,
              },
            })
          }
        }

        break

      case 'chairAssignment':
        if (messageEvent.publisher !== id) {
          const tempTopo = networkingZoneTopology
          if (Object.prototype.hasOwnProperty.call(tempTopo, messageEvent.message.tableID)) {
            tempTopo[messageEvent.message.tableID][messageEvent.message.chairID] =
              messageEvent.message.userData
          } else {
            tempTopo[messageEvent.message.tableID] = {}
            tempTopo[messageEvent.message.tableID][messageEvent.message.chairID] =
              messageEvent.message.userData
          }
          dispatch({
            type: eventActions.SET_STATE,
            payload: {
              networkingZoneTopology: tempTopo,
            },
          })
        }

        break

      case 'leaveChair':
        if (messageEvent.publisher !== id) {
          const tempTopo = networkingZoneTopology
          if (Object.prototype.hasOwnProperty.call(tempTopo, messageEvent.message.tableID)) {
            delete tempTopo[messageEvent.message.tableID][messageEvent.message.chairID]
          }

          dispatch({
            type: eventActions.SET_STATE,
            payload: {
              networkingZoneTopology: tempTopo,
            },
          })
        }

        break

      case 'deleteRequest':
        const tempRequestData = requestData
        if (Object.prototype.hasOwnProperty.call(tempRequestData, messageEvent.message.deleteFor)) {
          delete tempRequestData[messageEvent.message.deleteFor]
          dispatch({
            type: actions.SET_STATE,
            payload: {
              requestData: tempRequestData,
            },
          })
        }
        break

      case 'removeqanda':
        const tempqanda = qanda
        if (Object.prototype.hasOwnProperty.call(tempqanda, messageEvent.message.message_id)) {
          delete tempqanda[messageEvent.message.message_id]
          dispatch({
            type: streamActions.SET_STATE,
            payload: {
              qanda: tempqanda,
            },
          })
        }
        break

      case 'notification': {
        const key = `open${Date.now()}`
        const btn = (
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.notificationActions(
                key,
                messageEvent.message.notificationFor,
                messageEvent.message.calltoAction,
              )
            }
          >
            {messageEvent.message.linkText}
          </Button>
        )
        notification.open({
          message: messageEvent.message.message,
          description: messageEvent.message.description,
          btn: messageEvent.message.notificationFor === 'announcement' ? '' : btn,
          key,
          duration: 10,
        })

        dispatch({
          type: actions.SET_NOTIFICATION,
          payload: {
            actualload: messageEvent.message,
          },
        })
        break
      }
      case 'incominglead': {
        const key = `open${Date.now()}`
        const btn = (
          <Button
            type="primary"
            size="small"
            onClick={() => this.notificationActions(key, 'newlead', 'newleadincoming')}
          >
            {messageEvent.message.linkText}
          </Button>
        )
        notification.open({
          message: 'New Lead Recieved',
          description: `Recieved new contact by the name of: ${messageEvent.message.name}`,
          btn: messageEvent.message.notificationFor === 'announcement' ? '' : btn,
          key,
          duration: 10,
        })

        dispatch({
          type: eventActions.SET_LEAD,
          payload: {
            actualload: {
              channelID: messageEvent.channel,
              id: messageEvent.message.id,
              message: messageEvent.message,
              timetoken: messageEvent.timetoken,
            },
          },
        })
        break
      }
      default:
    }
  }

  render() {
    const { isSidebarOpen, currentScreen, pubnub, listnerInitialized, dispatch } = this.props
    return (
      <PubNubProvider client={pubnub}>
        {Object.keys(pubnub).length > 0 ? (
          <PubNubConsumer>
            {client => {
              {
                if (!listnerInitialized) {
                  client.addListener({
                    message: messageEvent => {
                      this.handleMessages(messageEvent)
                    },
                    signal: s => {
                      // console.log("signal aaya, signal aaya")
                      console.log(s)
                    },
                    status: s => {
                      console.log('Lets see if it is subscribed')
                      console.log(s.subscribedChannels)
                    },
                  })
                  dispatch({
                    type: actions.SET_STATE,
                    payload: {
                      listnerInitialized: true,
                    },
                  })
                }
              }
            }}
          </PubNubConsumer>
        ) : (
          ''
        )}
        <div
          className={
            isSidebarOpen ? `${styles.settings} ${styles.settingsOpened}` : styles.settings
          }
        >
          {currentScreen === 'home' ? <HomeScreen /> : <ChatWindow />}
        </div>
      </PubNubProvider>
    )
  }
}

export default Chat
