import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Tooltip } from 'antd'
import Avatar from 'components/CleanUIComponents/Avatar'
import capitalizeFirstLetters from 'capitalize-first-letters'
import actions from 'redux/virtual/event/actions'
import '../visitingcard.less'

const { TextArea } = Input

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions,no-lonely-if */
@connect(({ virtualEvent }) => ({ virtualEvent }))
class VisitingCardLayout extends React.Component {
  changeCustomMessage = event => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        customMessageCard: event.target.value,
      },
    })
  }

  toggleNumber = () => {
    const {
      dispatch,
      virtualEvent: { hideNumber },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        hideNumber: !hideNumber,
      },
    })
  }

  render() {
    const {
      hideNumber,
      name,
      number,
      email,
      organization,
      designation,
      isVirtualMobileView,
      theDP,
      thereceivedCustomMessage,
      thefinalWidth,
      customMessageView,
      virtualEvent: { customMessageCard },
    } = this.props
    let phoneNumber = ''
    if (hideNumber === 'notRequired') {
      phoneNumber = number
    } else {
      if (hideNumber) {
        phoneNumber = (
          <>
            ************
            <Tooltip title="Show, your number will be sent">
              <i
                className="fa fa-eye-slash"
                style={{ cursor: 'pointer' }}
                onClick={this.toggleNumber}
              />
            </Tooltip>
          </>
        )
      } else {
        phoneNumber = (
          <>
            {number}&nbsp;&nbsp;
            <Tooltip title="Hide, your number won't be sent">
              <i className="fa fa-eye" style={{ cursor: 'pointer' }} onClick={this.toggleNumber} />
            </Tooltip>
          </>
        )
      }
    }

    let customMessage = (
      <>
        <span style={{ alignItems: 'left' }}>Custom Message</span>
        <TextArea
          rows={3}
          placeholder="Enter a personalized message"
          onChange={this.changeCustomMessage}
          value={customMessageCard}
        />
      </>
    )
    if (customMessageView === 'leads') {
      customMessage = (
        <>
          <span style={{ alignItems: 'left' }}>Message from {capitalizeFirstLetters(name)}:</span>{' '}
          <br />
          <span style={{ alignItems: 'left' }}>{thereceivedCustomMessage}</span>
        </>
      )
    }

    return (
      <>
        <div className="business-card" style={{ width: thefinalWidth, height: '100%' }}>
          <div className="profile">
            <Row justify="space-around" align="middle" gutter={16}>
              <Col xs={8} md={8} lg={6} xl={6} className="text-center avatarDesign">
                <Avatar src={theDP} size="90" />
              </Col>
              <Col xs={16} md={16} lg={18} xl={18}>
                <div className="profile-title">
                  <h2>
                    <b>{capitalizeFirstLetters(name)}</b>
                  </h2>
                  <h4>{capitalizeFirstLetters(organization)}</h4>
                  <span>{capitalizeFirstLetters(designation)}</span>
                </div>
              </Col>
            </Row>
          </div>
          <Row justify="space-around" align="middle">
            <Col xs={24} md={12} lg={12} xl={12} className="info-contact">
              <span>
                <i className="fa fa-phone" />
                &nbsp;&nbsp;{phoneNumber}
              </span>
              <br />
              <span>
                <i className="fa fa-envelope" />
                &nbsp;&nbsp;{email}
              </span>
            </Col>
            {!isVirtualMobileView ? (
              <Col xs={24} md={12} lg={12} xl={12} className="info-contact">
                {customMessage}
              </Col>
            ) : (
              ''
            )}
          </Row>
          {isVirtualMobileView ? (
            <Row justify="space-around" align="middle">
              <Col xs={24} md={12} lg={12} xl={12} className="info-contact">
                {customMessage}
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}

export default VisitingCardLayout
