import React from 'react'
import { Row, Col, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Showcase extends React.Component {
  retake = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        imageSrc: '',
      },
    })
  }

  goNext = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep + 1,
      },
    })
  }

  render() {
    const {
      photobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            <img src={imageSrc} alt="newImage" style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <h3>Looking Awesome!</h3>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center">
            <Button shape="circle" onClick={this.retake} size="large" danger>
              Retake
            </Button>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.retake} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="primary" onClick={this.goNext} size="medium">
              Next &nbsp; <RightOutlined />
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default Showcase
