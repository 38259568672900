import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import virtualSettings from './virtual/settings/reducers'
import virtualMenu from './virtual/menu/reducers'
import virtualSetup from './virtual/setup/reducers'
import virtualEvent from './virtual/event/reducers'
import virtualUser from './virtual/user/reducers'
import registerVirtualUser from './virtual/registerUser/reducers'
import Chat from './virtual/chat/reducers'
import agenda from './virtual/agenda/reducers'
import streamFeature from './virtual/streamfeature/reducers'
import meetings from './virtual/meetings/reducers'
import photobooth from './virtual/photobooth/reducers'
import payment from './virtual/payment/reducers'
import analytics from './virtual/analytics/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    virtualSettings,
    virtualMenu,
    virtualSetup,
    virtualEvent,
    virtualUser,
    registerVirtualUser,
    Chat,
    agenda,
    streamFeature,
    meetings,
    photobooth,
    payment,
    analytics,
  })
