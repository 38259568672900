import React from 'react'
import { Modal, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './socialwall.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class SocialWall extends React.Component {
  // constructor(props) {
  //   super(props)
  //   props.dispatch({
  //     type: actions.GET_AGENDA,
  //     payload: {},
  //   })
  // }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isSocialWallOpen: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isSocialWallOpen,socialWallURL },
    } = this.props
    return (
      <div>
        <Modal
          visible={isSocialWallOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="socialwallModal"
          maskClosable={false}
          maskStyle={{ marginTop: '48px' }}
          mask
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          {/* <Row gutter={24} id="modalHeader" className="modalHeader">
            <Col span={24}>
              <img
                src="./resources/images/agendaHeader.jpg"
                style={{ width: '100%' }}
                alt="modalHeader"
              />
            </Col>
          </Row> */}
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 50px)">
              <Col span={24}>
                <Iframe
                  url={socialWallURL}
                  width="100%"
                  height='calc(100vh - 50px)'
                  // id={newGameID}
                  // key={newGameID}
                  loading="eager"
                  className="theiFrame"
                  display="initial"
                  position="relative"
                  frameborder="0"
                  // style={{border:0,height:"800px",width:"100%"}} 
                  // loading="lazy"
                />
              </Col>
            </Scrollbars>
          </Row>
          {/* <Row gutter={24} id="modalFooter" className="modalFooter">
            <Col span={24}>
              <img
                src="./resources/images/agendaFooter.jpg"
                style={{ width: '100%' }}
                alt="modalFooter"
              />
            </Col>
          </Row> */}
        </Modal>
      </div>
    )
  }
}

export default SocialWall
