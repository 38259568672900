import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import Avatar from '../../../../../CleanUIComponents/Avatar'
import styles from './style.module.scss'

@connect(({ virtualUser }) => ({ virtualUser }))
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/LOGOUT',
    })
  }

  render() {
    const { virtualUser } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>{virtualUser.name || 'Anonymous'}</strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            {virtualUser.email}
            <br />
            {virtualUser.number || '-'}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          <i className={`${styles.menuIcon} icmn-exit`} />
          Logout
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        className="pull-right"
        style={{ position: 'absolute', top: 0 }}
      >
        <div className={styles.dropdown}>
          <Avatar
            src={
              virtualUser.avatarType === 'text'
                ? `https://via.placeholder.com/100x100.png/${virtualUser.avatarColor.substring(
                    1,
                  )}/fff?text=${virtualUser.avatarData}`
                : virtualUser.avatarData
            }
            border
            borderColor="white"
            size="10"
          />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
