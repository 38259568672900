import API from '../config/api'

export async function initiateOrder() {
  return API.get('createPaymentOrder')
}

export async function storeCreds(data) {
  // console.log('insideStoreCreds')
  // console.log(data)
  return API.post('validateSignature', data)
}

export async function initiatePayment() {
  return ''
}
