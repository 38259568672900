import React from 'react'
import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  getallUsers,
  initChat,
  saveChat,
  pubnubPublish,
  pubnubSubscribe,
  getChannels,
  pubnubFetchMessages,
  getLeads,
} from 'services/virtual/chat'
import { SystemMessage } from 'react-chat-elements'
import { message } from 'antd'
import MeetingInvite from 'components/LayoutComponents/Virtual/Chat/LayoutComponent/MeetingInvite'
import VisitingCard from 'components/LayoutComponents/Virtual/Chat/LayoutComponent/VisitingCard'
import uuid from 'react-uuid'
import { store as reduxStore } from 'index'
import eventActions from 'redux/virtual/event/actions'
import actions from './actions'

export function* GET_ALL_USERS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const allUsersData = yield call(getallUsers, accessToken)
    if (allUsersData.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          allUsers: allUsersData.data,
          loadingAttendeeList: false,
        },
      })
    } else {
      message.error('Cannot Fetch users for Chat. Try Logging in again.')
    }
  } catch (err) {
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* INIT_CHAT() {
  try {
    const state = yield select()
    const {
      virtualUser: { id },
    } = state
    const pubnub = yield call(initChat, id)
    yield put({
      type: actions.SET_STATE,
      payload: {
        pubnub,
      },
    })
    yield put({
      type: actions.GET_CHANNELS,
    })
  } catch (err) {
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_MESSAGE(data) {
  try {
    const state = yield select()
    const {
      Chat: { channelData },
      virtualUser: { id, name, organization, designation, avatarType, avatarData, avatarColor },
    } = state
    if (!Object.prototype.hasOwnProperty.call(channelData, data.payload.chatWith)) {
      yield put({
        type: actions.CREATE_CHANNEL,
        payload: {
          chatWith: data.payload.chatWith,
          pubnub: data.payload.pubnub,
          message: data.payload.message,
        },
      })
    } else {
      const finalMessage = {
        content: data.payload.message.content,
        publisher:
          channelData[data.payload.chatWith].parent !== null &&
          channelData[data.payload.chatWith].parent !== undefined
            ? channelData[data.payload.chatWith].parent
            : data.payload.message.publisher,
        type: data.payload.message.type,
      }

      const publishData = {
        channelID: channelData[data.payload.chatWith].channelID,
        message: finalMessage,
        pubnub: data.payload.pubnub,
      }
      const result = yield call(pubnubPublish, publishData)

      const utcSeconds = Math.round(result.timetoken / 10000000)
      const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds)

      let toAdd = {}
      if (
        data.payload.message.type === 'message' ||
        data.payload.message.type === 'videomeet' ||
        data.payload.message.type === 'visitingcard'
      ) {
        toAdd = {
          position: 'right',
          type: 'text',
          category: data.payload.message.type,
          text: data.payload.message.content,
          date: d,
          status: 'recieved',
        }
      }
      yield put({
        type: actions.SET_MESSAGE,
        payload: {
          chatWith: data.payload.chatWith,
          lastTime: Math.round(result.timetoken / 10000000),
          readStatus: 'read',
          unreadCount: 0,
          toAdd,
        },
      })

      if (Object.prototype.hasOwnProperty.call(channelData, data.payload.chatWith)) {
        if (
          Object.prototype.hasOwnProperty.call(channelData[data.payload.chatWith], 'assignedTo')
        ) {
          if (
            Object.keys(channelData[data.payload.chatWith].assignedTo).length === 0 &&
            channelData[data.payload.chatWith].channelType === 'business'
          ) {
            const publishSecondaryData = {
              channelID: data.payload.chatWith,
              message: {
                type: 'subscribe',
                publisher: id,
                subscribeTo: channelData[data.payload.chatWith].channelID,
                ChannelType: 'business',
                assignedTo: {},
                requestFor: data.payload.chatWith,
                userMeta: {
                  name,
                  organization,
                  designation,
                  avatar_type: avatarType,
                  avatar_data: avatarData,
                  avatar_color: avatarColor,
                  login_status: true,
                },
              },
              pubnub: data.payload.pubnub,
            }
            yield call(pubnubPublish, publishSecondaryData)

            yield put({
              type: actions.SET_MESSAGE,
              payload: {
                chatWith: data.payload.chatWith,
                lastTime: new Date(0),
                readStatus: 'read',
                unreadCount: 0,
                toAdd: {
                  position: 'left',
                  type: 'text',
                  category: 'message',
                  text: `Hello, Welcome to ${
                    channelData[data.payload.chatWith].userMeta.name
                  }. Our representative will be assigned to you shortly. We've an average waiting time of 2 minutes. You can share your queries post the representative is assigned.`,
                  date: new Date(0),
                },
              },
            })
          }
        }
      }
    }
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        chatWith: data.payload.chatWith,
        readStatus: 'read',
        unreadCount: 0,
        lastTime: new Date(0),
        toAdd: {
          position: 'right',
          type: 'text',
          text: data.payload.message.content,
          date: new Date(),
          status: 'waiting',
        },
      },
    })
  }
}

export function* CREATE_CHANNEL(data) {
  try {
    const generatedChannelName = uuid()
    const state = yield select()
    const {
      virtualUser: {
        accessToken,
        id,
        name,
        organization,
        designation,
        avatarType,
        avatarData,
        avatarColor,
      },
      Chat: { allUsers, userType, visitedExhibitors },
    } = state

    const user =
      userType === 'attendee'
        ? allUsers[data.payload.chatWith]
        : visitedExhibitors[data.payload.chatWith]
    const ChannelType = userType === 'attendee' ? 'attendee' : 'business'
    yield call(pubnubPublish, {
      pubnub: data.payload.pubnub,
      channelID: data.payload.chatWith,
      message: {
        type: 'subscribe',
        subscribeTo: generatedChannelName,
        ChannelType,
        publisher: id,
        assignedTo: {},
        requestFor: data.payload.chatWith,
        userMeta: {
          name,
          organization,
          designation,
          avatar_type: avatarType,
          avatar_data: avatarData,
          avatar_color: avatarColor,
          login_status: true,
        },
      },
    })
    const apiresult = yield call(saveChat, {
      ChannelName: generatedChannelName,
      ChannelType,
      token: accessToken,
      ChatWith: data.payload.chatWith,
      assignedTo: {},
    })
    if (apiresult.status === 200) {
      yield put({
        type: actions.SUBSCRIBE_CHANNELS,
        payload: {
          channels: [generatedChannelName],
          pubnub: data.payload.pubnub,
        },
      })

      yield put({
        type: actions.ADD_CHANNEL,
        payload: {
          actualload: {
            channelID: generatedChannelName,
            channelType: 'private',
            chatWith: data.payload.chatWith,
            messages: [],
            lastTime: 631132200,
            userMeta: {
              name: user.name,
              avatar_type: user.avatar_type,
              avatar_data: user.avatar_data,
              avatar_color: user.avatar_color,
              login_status: true,
            },
          },
        },
      })
      yield put({
        type: actions.SEND_MESSAGE,
        payload: {
          pubnub: data.payload.pubnub,
          message: data.payload.message,
          chatWith: data.payload.chatWith,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    console.log(err.status)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SUBSCRIBE_CHANNELS(data) {
  try {
    const subsResult = yield call(pubnubSubscribe, data.payload)
    console.log('response of subscription')
    console.log(subsResult)
  } catch (err) {
    console.log(err.status)
  }
}

export function* GET_CHANNELS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken, id, roles, assignedScene },
      Chat: { pubnub },
    } = state
    const result = yield call(getChannels, accessToken)
    const channels = []
    if (Object.keys(result.data).length > 0) {
      const channelDataAppended = {}
      Object.entries(result.data).map(key => {
        channels.push(key[1].channelID)
        channelDataAppended[key[0]] = {
          channelID: key[1].channelID,
          chatWith: key[1].chatWith,
          lastTime: 631132200,
          messages: [],
          readStatus: 'read',
          unreadCount: 0,
          channelType: key[1].channelType,
          userMeta: key[1].userMeta,
          parent:
            roles.includes('ROLE_EXHIBITOR') && key[1].channelType === 'business'
              ? key[1].parent
              : null,
          assignedTo: Object.prototype.hasOwnProperty.call(key[1], 'assignedTo')
            ? key[1].assignedTo
            : {},
        }
        return ''
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          channelData: channelDataAppended,
          currentTab: 'messages',
        },
      })

      yield put({
        type: actions.FETCH_MESSAGES,
        payload: {
          channels,
          pubnub,
        },
      })
    }
    channels.push(id)
    if (roles.includes('ROLE_EXHIBITOR')) {
      assignedScene.forEach(element => {
        channels.push(element)
      })
      yield put({
        type: actions.FETCH_REQUESTS,
        payload: {
          channels: assignedScene,
          pubnub,
        },
      })

      yield put({
        type: actions.FETCH_LEADS,
        payload: {
          theScene: assignedScene,
        },
      })
    }
    channels.push('wiz365-notifications')
    yield put({
      type: actions.SUBSCRIBE_CHANNELS,
      payload: {
        channels,
        pubnub,
      },
    })
  } catch (err) {
    console.log('GET CHANNEL ERROR')
    console.log(err)
    console.log(err.response)
  }
}

export function* FETCH_MESSAGES(data) {
  try {
    const state = yield select()
    const {
      Chat: { channelData },
      virtualUser: { id },
    } = state
    const fetchResult = yield call(pubnubFetchMessages, data.payload)

    Object.entries(fetchResult.channels).map(key => {
      let selectedChannel = ''

      Object.entries(channelData).map(item => {
        if (item[1].channelID === key[0]) {
          selectedChannel = item[1].chatWith
          return item[1].chatWith
        }
        return ''
      })
      const messages = []
      let lastTime = 631132200
      key[1].forEach(obj => {
        const utcSeconds = Math.round(obj.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        let actualText = obj.message.content
        if (obj.message.type === 'videomeet') {
          actualText = <MeetingInvite id={obj.message.content.props.id} />
        }
        if (obj.message.type === 'visitingcard') {
          actualText = <VisitingCard userData={obj.message.content.props.userData} />
        }
        if (obj.message.type === 'assignExhibitor') {
          actualText = (
            <SystemMessage text={`${obj.message.assignedTo.name} has been assigned to you.`} />
          )
        }

        let position = 'left'
        if (obj.message.publisher === id) {
          position = 'right'
        }

        if (channelData[selectedChannel].parent != null) {
          if (channelData[selectedChannel].parent === obj.message.publisher) {
            position = 'right'
          }
          if (channelData[selectedChannel].parent === obj.message.parentPublisher) {
            position = 'right'
          }
        }
        const finalJSON = {
          position,
          type: 'text',
          category: obj.message.type,
          text: actualText,
          date: d,
        }
        messages.push(finalJSON)
        lastTime = Math.round(obj.timetoken / 10000000)
      })
      if (selectedChannel !== '') {
        reduxStore.dispatch({
          type: actions.SET_HISTORY_MESSAGE,
          payload: {
            chatWith: selectedChannel,
            lastTime,
            toAdd: messages,
          },
        })
      }
      return ''
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* FETCH_REQUESTS(data) {
  try {
    const fetchResult = yield call(pubnubFetchMessages, data.payload)
    const temporaryFetchRequest = {}
    Object.entries(fetchResult.channels).map(key => {
      key[1].forEach(obj => {
        if (obj.message.type === 'subscribe' && obj.message.ChannelType === 'business') {
          if (Object.prototype.hasOwnProperty.call(obj.message, 'publisher')) {
            temporaryFetchRequest[obj.message.publisher] = {
              channelID: obj.message.subscribeTo,
              channelType: obj.message.ChannelType,
              chatWith: obj.message.publisher,
              requestFor: obj.message.requestFor,
              userMeta: obj.message.userMeta,
            }

            // reduxStore.dispatch({
            //   type: actions.ADD_REQUESTS,
            //   payload: {
            //     actualload: {
            //       channelID: obj.message.subscribeTo,
            //       channelType: obj.message.ChannelType,
            //       chatWith: obj.message.publisher,
            //       requestFor: obj.message.requestFor,
            //       userMeta: obj.message.userMeta,
            //     },
            //   },
            // })
          }
        }
        if (obj.message.type === 'deleteRequest') {
          if (Object.prototype.hasOwnProperty.call(temporaryFetchRequest, obj.message.deleteFor)) {
            delete temporaryFetchRequest[obj.message.deleteFor]
          }
        }
      })
      return ''
    })

    reduxStore.dispatch({
      type: actions.SET_STATE,
      payload: {
        requestData: temporaryFetchRequest,
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* ASSIGN_EXHIBITOR(data) {
  try {
    const state = yield select()
    const {
      Chat: { requestData, pubnub, channelData },
      virtualUser: { id, name, accessToken },
    } = state

    const selectedRequest = requestData[data.payload.chatWith]
    yield put({
      type: actions.ADD_CHANNEL,
      payload: {
        actualload: {
          channelID: selectedRequest.channelID,
          channelType: selectedRequest.channelType,
          chatWith: selectedRequest.chatWith,
          parent: selectedRequest.requestFor,
          messages: [],
          lastTime: 631132200,
          userMeta: selectedRequest.userMeta,
        },
      },
    })

    yield call(pubnubSubscribe, { pubnub, channels: [selectedRequest.channelID] })
    const fetchResult = yield call(pubnubFetchMessages, {
      pubnub,
      channels: [selectedRequest.channelID],
    })
    Object.entries(fetchResult.channels).map(key => {
      let selectedChannel = ''

      Object.entries(channelData).map(item => {
        if (item[1].channelID === key[0]) {
          selectedChannel = item[1].chatWith
          return item[1].chatWith
        }
        return ''
      })
      const messages = []
      let lastTime = 631132200
      key[1].forEach(obj => {
        const utcSeconds = Math.round(obj.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        let actualText = obj.message.content
        if (obj.message.type === 'videomeet') {
          actualText = <MeetingInvite id={obj.message.data} />
        }
        const finalJSON = {
          position: obj.message.publisher === data.payload.chatWith ? 'left' : 'right',
          type: 'text',
          category: obj.message.type,
          text: actualText,
          date: d,
        }
        messages.push(finalJSON)
        lastTime = Math.round(obj.timetoken / 10000000)
      })
      if (selectedChannel !== '') {
        reduxStore.dispatch({
          type: actions.SET_HISTORY_MESSAGE,
          payload: {
            chatWith: selectedChannel,
            lastTime,
            toAdd: messages,
          },
        })
      }
      return ''
    })

    const tempRequestData = requestData
    delete tempRequestData[data.payload.chatWith]
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestData: tempRequestData,
        currentScreen: 'chat',
        currentTab: 'messages',
        currentChat: data.payload.chatWith,
      },
    })

    yield call(pubnubPublish, {
      pubnub,
      channelID: selectedRequest.channelID,
      message: {
        type: 'assignExhibitor',
        parentPublisher: selectedRequest.requestFor,
        assignedTo: {
          id,
          name,
        },
      },
    })

    yield call(saveChat, {
      ChannelName: selectedRequest.channelID,
      ChannelType: 'business',
      token: accessToken,
      ChatWith: selectedRequest.chatWith,
      assignedTo: {
        id,
        name,
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* UNASSIGN_EXHIBITOR(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub, channelData },
      virtualUser: { accessToken },
    } = state

    const selectedChannel = channelData[data.payload.currentChat]
    yield call(pubnubPublish, {
      pubnub,
      channelID: selectedChannel.channelID,
      message: {
        type: 'unassignExhibitor',
        parentPublisher: selectedChannel.requestFor,
        assignedTo: {},
      },
    })

    yield call(saveChat, {
      ChannelName: selectedChannel.channelID,
      ChannelType: 'business',
      token: accessToken,
      ChatWith: selectedChannel.chatWith,
      assignedTo: {},
    })

    const tempchannelData = channelData
    delete tempchannelData[data.payload.currentChat]
    yield put({
      type: actions.SET_STATE,
      payload: {
        channelData: tempchannelData,
        currentScreen: 'homescreen',
        currentTab: 'messages',
        currentChat: '',
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* DELETE_REQUEST(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub, requestData },
    } = state

    const selectedRequest = requestData[data.payload.chatWith]
    yield call(pubnubPublish, {
      pubnub,
      channelID: selectedRequest.requestFor,
      message: {
        type: 'deleteRequest',
        deleteFor: data.payload.chatWith,
        ChannelType: 'business',
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* FETCH_LEADS(data) {
  try {
    const result = yield call(getLeads, data.payload.theScene)
    if (result.status === 200) {
      yield put({
        type: eventActions.SET_STATE,
        payload: {
          leads: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_USERS, GET_ALL_USERS),
    takeEvery(actions.INIT_CHAT, INIT_CHAT),
    takeEvery(actions.CREATE_CHANNEL, CREATE_CHANNEL),
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.SUBSCRIBE_CHANNELS, SUBSCRIBE_CHANNELS),
    takeEvery(actions.GET_CHANNELS, GET_CHANNELS),
    takeEvery(actions.FETCH_MESSAGES, FETCH_MESSAGES),
    takeEvery(actions.FETCH_REQUESTS, FETCH_REQUESTS),
    takeEvery(actions.ASSIGN_EXHIBITOR, ASSIGN_EXHIBITOR),
    takeEvery(actions.UNASSIGN_EXHIBITOR, UNASSIGN_EXHIBITOR),
    takeEvery(actions.DELETE_REQUEST, DELETE_REQUEST),
    takeEvery(actions.FETCH_LEADS, FETCH_LEADS),
  ])
}
