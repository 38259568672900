import actions from './actions'

const initialState = {
  userID: '',
  name: '',
  number: '',
  email: '',
  password: '',
  organization: '',
  designation: '',
  avatarType: 'text',
  avatarData: '',
  avatarColor: '',
  loading: false,
  imageURL: '',
  current: 0,
  address: '',
  usertype: '',
  options: [],
  colors: ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'],
  emailStatus: '',
  phoneStatus: '',
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
