import API from '../config/api'

export async function completeRegistration(data) {
  return API.post('auth/signup', data.registration)
}

export async function validateEmail(data) {
  return API.post('auth/checkEmail', data.finalData)
}

export async function validatePhone(data) {
  return API.post('auth/checkNumber', data.finalData)
}

export async function login(data) {
  return API.post('auth/signin', data.finalData)
}

export async function getUser(data) {
  return API.get(`getUser`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function logout(data) {
  return API.get(`signout`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
