import { all, call, put } from 'redux-saga/effects'
import { getAgenda } from 'services/virtual/setup'
import { message } from 'antd'
import actions from './actions'

export function* GET_AGENDA() {
  try {
    const setupData = yield call(getAgenda)
    if (Object.keys(setupData.data).length > 0) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          agendas: setupData.data,
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.GET_AGENDA, GET_AGENDA)
  ])
}
